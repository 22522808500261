








































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { AppModule, DeviceType } from '@/store/modules/app'
import { locales } from '@/utils'

@Component({
  name: 'PropertyDetailHeader'
})

export default class extends Vue {
  @Prop({ required: true }) private property!: any
  @Prop({ default: false }) private isEdit!: boolean
  @Prop({ default: 'el' }) private language!: string
  @Prop({ default: false }) private loading!: boolean

  private languages = locales
  private lang = this.language

  get isMobile() {
    return AppModule.device === DeviceType.Mobile
  }

  get title() {
    if (this.property.auction) {
      return this.isEdit ? this.$t('route.editAuction') : this.$t('route.createAuction')
    } else {
      return this.isEdit ? this.$t('propertyDetail.editProperty') : this.$t('propertyDetail.newProperty')
    }
  }

  private handleCommand(command: string) {
    this.$emit('set-command', command)
  }
}
